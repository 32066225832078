import { Primise } from "enums/primisesType";

export type RangeDataType = {
  label: string;
  min: number;
  max: number;
  step: number;
  filterKeys: [string, string];
};

export const header = [
  {
    key: "floor",
    title: "Этаж",
    sortable: true,
  },
  {
    key: "section",
    title: "Секция",
    sortable: true,
  },
  {
    key: "name",
    title: "Номер",
  },
  {
    key: "building",
    title: "Дом",
    sortable: true,
  },
  {
    key: "price",
    title: "Стоимость",
    sortable: true,
  },
  {
    key: "size",
    title: "Площадь",
    sortable: true,
  },
  {
    key: "status",
    title: "Статус",
    sortable: true,
  },
];

export const rangeData: RangeDataType[] = [
  {
    label: "Площадь",
    min: 5,
    max: 150,
    step: 1,
    filterKeys: ["min_size", "max_size"],
  },
  {
    label: "Стоимость",
    min: 0,
    max: 43000000,
    step: 1,
    filterKeys: ["min_price", "max_price"],
  },
  {
    label: "Этаж",
    min: 1,
    max: 10,
    step: 1,
    filterKeys: ["min_floor", "max_floor"],
  },
];

export const primisesTypeData = [
  { label: "Квартиры", value: Primise.APARTMENT },
  { label: "Паркинги", value: Primise.PARKING },
  { label: "Кладовые", value: Primise.STOREROOM },
  { label: "Kоммерческие ", value: Primise.COMMERCIAL },
];

export function getRoomDeclension(number: number) {
  const titles = ["комната", "комнаты", "комнат"];
  const cases = [2, 0, 1, 1, 1, 2];
  const mod100 = number % 100;
  const mod10 = number % 10;

  return titles[mod100 > 4 && mod100 < 20 ? 2 : cases[mod10 < 5 ? mod10 : 5]];
}

const number = 5;
console.log(`${number} ${getRoomDeclension(number)}`); // "5 комнат"
