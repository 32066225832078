import { useState } from "react";
import BreadCrump from "components/BreadCrump";
import BellIcon from "components/constant/icons/BellIcon";
import { useGetAllNotificationsQuery } from "data/api/notification";
import { timeSince } from "./notificationTimeCalculate";
import { objectToQueryString } from "utils/objectToQuery";
import { useDispatch } from "react-redux";
import { toggleNotificationModal } from "store/HeaderReducer";



const Notifications = () => {
  const [pagination, setPagination] = useState({ page: 1, limit: 5 });

  const dispatch = useDispatch();

  const { data: notifications } = useGetAllNotificationsQuery(
    objectToQueryString(pagination)
  );

  const handleOpenNotificationModal = () => {
    dispatch(toggleNotificationModal(true));
  };

  if (!notifications?.data.length) {
    return null;
  }

  return (
    <div className=" p-[15px] xl:p-[20px] rounded-[8px] bg-white-default">
      <BreadCrump
        title="Уведомления"
        className="text-[16px] font-medium"
        link=""
        leftIcon={<BellIcon color="#3583FF" />}
      />

      <div className="flex flex-col gap-[10px] mt-[25px] ">
        {notifications.data.map((item, i) => (
          <div key={item.id}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col justify-between gap-[5px] text-accent-default">
                <h3 className="text-[13px] xl:text-[14px] leading-[18px] m-0 p-0 font-medium">
                  {item.title}
                </h3>
                <p className=" text-[11px] xl:text-[12px] m-0 p-0 leading-[16px] font-medium truncate w-[220px]">
                  {item.description}
                </p>
              </div>
              <span className=" text-[11px] xl:text-[12px] leading-[16px] font-medium opacity-50">
                {timeSince(item.created_at)}
              </span>
            </div>
            {!(notifications.data.length === i + 1) && (
              <hr className="mt-[5px] border-[1px] text-boder-default" />
            )}
          </div>
        ))}
      </div>
      {notifications.data.length + 1 > pagination.limit ? (
        <button
          onClick={handleOpenNotificationModal}
          className="text-[13px] xl:text-[14px] leading-[24px] border-none 
        outline-none bg-white-default text-blue-default w-full mt-[25px]"
        >
          Еще
        </button>
      ) : null}
    </div>
  );
};

export default Notifications;
