import React, { useEffect, useRef, useState } from "react";

import CancelIcon from "components/constant/icons/CancelIcon";
import CheckIcon from "components/constant/icons/CheckIcon";
import { images } from "utils/resource";
import {
  useGetAllNotificationsQuery,
  useGetOneNotificationMutation,
} from "data/api/notification";
import { useDispatch } from "react-redux";
import { toggleNotificationModal } from "store/HeaderReducer";
import { useAcceptInvitationMutation } from "data/api/event";
import showToast from "utils/showToast";
import { timeSince } from "./notificationTimeCalculate";
import { objectToQueryString } from "utils/objectToQuery";
import { Notification } from "data/api/notification/type";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { PaginationType } from "modules/BaseClients";
import { renderImage } from "utils/renderImage";

type paginationType = {
  page: number;
  limit: number;
};

export enum NotificationType {
  EVENT = "event",
  CREATED_EVENT = "created_event",
  WARNING_EVENT = "warning_event",
  CREATED_NEWS = "created_news",
  END_LEAD = "end_lead",
  AGENT_REQUEST_FOR_AGENCY = "agent_request_for_agency",
}

const NotificationsModal = () => {
  const modalRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const [paginationInfo, setPaginationInfo] = useState<PaginationType | null>({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });

  const [pagination, setPagination] = useState<paginationType>({
    page: 1,
    limit: 30,
  });

  const [id, setId] = useState<number>();

  const [notifications, setNotification] = useState<Notification[]>([]);

  const { data, refetch, isLoading } = useGetAllNotificationsQuery(
    objectToQueryString(pagination)
  );
  const [getOneMutate] = useGetOneNotificationMutation();

  const [isPaginationChanged, setIsPaginationChanged] = useState(false);

  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [id]);

  async function getOne(id: number) {
    await getOneMutate(id);
  }

  useEffect(() => {
    if (!isPaginationChanged) {
      setNotification(data?.data || []);
    } else {
      setNotification((prev) => [...prev, ...(data?.data || [])]);
    }
    setPaginationInfo(data?.meta?.links || null);
  }, [data]);

  const [acceptInvitationMutation] = useAcceptInvitationMutation();

  const handleAcceptOrRejectInvitation = async (
    event_id: number,
    is_accepted: boolean
  ) => {
    const { data } = await acceptInvitationMutation({
      data: { event_id: event_id, is_accepted: is_accepted },
    });
    refetch();
    if (data?.data.is_accepted) {
      showToast("Приглашение принято", "success", 1000);
      return;
    }
    showToast("Приглашение отклонено", "warning", 1000);
  };

  const hanldeCloseToggleNotificationModal = () => {
    dispatch(toggleNotificationModal(false));
  };

  const handleAddMoreNotification = () => {
    setIsPaginationChanged(true);
    setPagination((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const notificationLinks = {
    event: "events/view",
    created_event: "events/view",
    warning_event: "events/view",
    created_news: "news/view",
    end_lead: "",
    agent_request_for_agency: "",
  };

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        hanldeCloseToggleNotificationModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={modalRef}
      className="z-50 bg-white-default rounded-t-[20px] sm:rounded-[20px] fixed right-0 bottom-0 sm:right-5 sm:bottom-5 w-full sm:w-[438px] shadow-sm border border-boder-default"
    >
      <div className="text-center relative pt-5 ">
        <h3 className="text-[20px] text-accent-default font-dewi font-extrabold">
          Уведомления
        </h3>
        <div
          onClick={hanldeCloseToggleNotificationModal}
          className=" cursor-pointer absolute right-[20px] top-[50%] -translate-y-[50%]"
        >
          <CancelIcon />
        </div>
        <div className="h-[1px] relative left-0 right-0 bg-boder-default my-3"></div>
      </div>

      {/* list */}
      <div className="flex flex-col gap-2 px-5 max-h-[70vh] overflow-auto toolbar-scroll">
        {notifications?.length ? (
          notifications.map((item, i) => (
            <div
              key={item.id}
              // onClick={() => setId(item.id)}
              className="flex flex-col pt-1"
            >
              <div className="flex gap-3 items-center mb-[10px]">
                {!item.is_read ? (
                  <div className="w-[5px] h-[5px] bg-red-default rounded-full"></div>
                ) : null}

                <div className="flex items-center gap-3 w-full">
                  {item.type === NotificationType.EVENT && (
                    <img
                      src={
                        renderImage(item.object.photo || "") ?? images.eventsImg
                      }
                      width={50}
                      height={40}
                      className="w-[50px] h-[40px] rounded"
                      alt="event"
                    />
                  )}

                  <div className="flex flex-col gap-1 text-accent-default">
                    {item.type === NotificationType.EVENT ? (
                      <>
                        <p className="text-sm font-medium ">
                          {item.type === "event" && "Запись на мероприятие"}
                        </p>
                        <p className="text-[12px] font-medium">
                          Вы были занисаны на
                          <Link
                            onClick={() => setId(item.id)}
                            to={`events/view/${item.object_id}`}
                            className=" text-blue-default underline ml-1"
                          >
                            мероприятие
                          </Link>
                        </p>
                      </>
                    ) : item.type === NotificationType.END_LEAD ||
                      item.type ===
                        NotificationType.AGENT_REQUEST_FOR_AGENCY ? (
                      <div
                        onClick={() => setId(item.id)}
                        className="flex flex-col justify-between gap-[5px] text-accent-default w-[250px] sm:w-[300px] notification-content"
                      >
                        <h3 className="text-[13px] xl:text-[14px] leading-[18px]  m-0 p-0 font-medium">
                          {item.title}
                        </h3>
                        <p className=" text-[11px] xl:text-[12px] m-0 p-0 leading-[16px] font-medium ">
                          {item.description}
                        </p>
                      </div>
                    ) : (
                      <Link
                        to={`${
                          notificationLinks[item.type as NotificationType]
                        }/${item.object_id}`}
                        onClick={() => setId(item.id)}
                        className="flex flex-col justify-between gap-[5px] text-accent-default cursor-pointer  w-[250px] sm:w-[300px] notification-content"
                      >
                        <h3 className="text-[13px] xl:text-[14px] leading-[18px] m-0 p-0 font-medium">
                          {item.title}
                        </h3>
                        <p className=" text-[11px] xl:text-[12px] m-0 p-0 leading-[16px] font-medium ">
                          {item.description}
                        </p>
                      </Link>
                    )}
                  </div>
                  <p className="text-[12px] text-accent-default opacity-60 ml-auto">
                    {timeSince(item.created_at)}
                  </p>
                </div>
              </div>
              {item.type === NotificationType.EVENT ? (
                <div className="flex gap-2">
                  <button
                    onClick={() =>
                      handleAcceptOrRejectInvitation(item.object_id, false)
                    }
                    className="py-[10px] flex-1 border border-red-default rounded flex items-center justify-center"
                  >
                    <CancelIcon color="#EF3B24" />
                  </button>
                  <button
                    onClick={() =>
                      handleAcceptOrRejectInvitation(item.object_id, true)
                    }
                    className="py-[10px] flex-1 border border-[#4BCC36] rounded flex items-center justify-center"
                  >
                    <CheckIcon />
                  </button>
                </div>
              ) : null}
              {notifications?.length - 1 !== i ? (
                <div className="h-[1px] relative left-0 right-0 bg-boder-default mt-3"></div>
              ) : null}
            </div>
          ))
        ) : !isLoading ? (
          <div className="text-accent-default text-center mb-4 opacity-70">
            Пока нет уведомлений
          </div>
        ) : (
          <div className="min-h-[16vh]">
            <Loader />
          </div>
        )}
        {notifications?.length &&
        paginationInfo?.currPage !== paginationInfo?.totalPage ? (
          <button
            onClick={handleAddMoreNotification}
            className="text-[13px] xl:text-[14px] leading-[24px] border-none 
            outline-none bg-white-default text-blue-default w-full my-[20px]"
          >
            Еще
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationsModal;
