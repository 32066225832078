import { BuildingRequest } from "data/api/project/type";
import { DealStatus, DealSteps, LeadOpStatus } from "enums/deals";
import { Primise } from "enums/primisesType";

export interface Client {
  id: number;
  fullname: string;
  phone_number: string;
}

export interface Agent {
  id: number;
  fullName: string;
}

export interface Manager {
  id: number;
  fullName: string;
}
interface City {
  id: number;
  name: string;
  lat: string;
  long: string;
}
export interface Project {
  photos: string[];
  id: number;
  name: string;
  detailed_description: string;
  brief_description: string;
  description: string;
  photo: string;
  price: number;
  location: string;
  long: string;
  lat: string;
  link: string;
  end_date: string;
  premise_count: string;
  premise_type: string;
  apartment_count: number;
  commercial_count: number;
  parking_count: number;
  storeroom_count: number;
  city: City;
  company_link: string;
  building_link: string;
  project_link: string;
  buildings: BuildingRequest[];
}

export interface Premise {
  id: number;
  name: string;
  floor: number;
  rooms: number;
  photos: string[];
  type: string;
  price: string;
}

export interface LeadOp {
  id: number;
  status: LeadOpStatus;
}

export interface Deal {
  id: number;
  created_at: string;
  updated_at: string;
  client: Client;
  client_id: string;
  agent: Agent;
  agent_id: string;
  manager: Manager;
  manager_id: string;
  project: Project;
  project_id: string;
  premise: Premise;
  premise_id: string;
  lead_number: number;
  fee: number;
  current_status: LeadOpStatus;
  state: DealStatus;
  lead_ops: LeadOp[];
}

export const primisesTypeDataInDeals = [
  { label: "Квартиры", value: Primise.APARTMENT },
  { label: "Паркинги", value: Primise.PARKING },
  { label: "Кладовые", value: Primise.STOREROOM },
  { label: "Kоммерческое", value: Primise.COMMERCIAL },
];
