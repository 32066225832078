import React, { useEffect, useState } from "react";

import ApartmentList from "../ApartmentList";
import ParkingOrStoreroom from "../ParkingStoreComercial";

import { useGetPremisesQuery } from "data/api/premises";
import { Primise } from "enums/primisesType";
import { useLocation } from "react-router";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { PaginationType } from "modules/BaseClients";
import { Apartment } from "../ApartmentList/dataTransformer";

interface propsType {
  children: React.ReactNode;
}

interface propsTypePaginationComponent {
  children: React.ReactNode;
  paginationInfo?: PaginationType;
  handleUpdateList: () => void;
}

const RenderContent = () => {
  const location = useLocation();

  const [apartments, setApartments] = useState<Apartment[] | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<PaginationType>({
    currPage: 1,
    limit: 50,
    total: 0,
    totalPage: 0,
  });

  // const [updatePage, setUpdatePage] = useState<boolean>(false);

  const queryParams = new URLSearchParams(location.search);

  const header = useSelector((state: RootState) => state.header);

  const type = useSelector((state: RootState) => state.apartment.type);

  const primisesType = queryParams.get("type") || type || Primise.APARTMENT;

  const params = location.search.slice(1);

  const defCity = params.includes("city_id")
    ? ""
    : params === ""
    ? `city_id=${header.city_id}`
    : `&city_id=${header.city_id}`;

  const defType = params.includes("type") ? "" : `&type=${primisesType}`;

  const paginationQuery = `&page=${paginationInfo.currPage}&limit=${paginationInfo.limit}`;

  const { data, isLoading } = useGetPremisesQuery(
    params + defCity + defType + paginationQuery
  );
  useEffect(() => {
    if (data) {
      if (paginationInfo.currPage === 1) {
        setApartments(data.data || null);
      } else {
        setApartments((prev) => [...(prev || []), ...(data.data || [])]);
      }

      setPaginationInfo((prev) => ({
        ...prev,
        total: data?.meta?.links?.total || 0,
        totalPage: data?.meta?.links?.totalPage || 0,
      }));
    }
  }, [data]);

  useEffect(() => {
    setPaginationInfo((prev) => ({
      ...prev,
      currPage: 1,
    }));
  }, [params, defCity, defType]);

  const handleUpdateList = () => {
    setPaginationInfo((prev) => ({
      ...prev,
      currPage: prev.currPage + 1,
    }));
  };

  const roomlist = apartments ?? [];

  switch (primisesType) {
    case Primise.STOREROOM:
      return (
        <ApartmentsWithPagination
          paginationInfo={paginationInfo}
          handleUpdateList={handleUpdateList}
        >
          <ParkingOrStoreroom data={roomlist} isLoading={isLoading} />
        </ApartmentsWithPagination>
      );
    case Primise.PARKING:
      return (
        <ApartmentsWithPagination
          paginationInfo={paginationInfo}
          handleUpdateList={handleUpdateList}
        >
          <ParkingOrStoreroom data={roomlist} isLoading={isLoading} />
        </ApartmentsWithPagination>
      );
    default:
      return (
        <ApartmentsWithPagination
          paginationInfo={paginationInfo}
          handleUpdateList={handleUpdateList}
        >
          <ApartmentList data={roomlist} isLoading={isLoading} />
        </ApartmentsWithPagination>
      );
  }
};

const ApartmentsWithPagination = ({
  children,
  paginationInfo,
  handleUpdateList,
}: propsTypePaginationComponent) => {
  return (
    <>
      {children}
      {paginationInfo
        ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
            <button
              type={"button"}
              className={
                "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
              }
              onClick={() => handleUpdateList()}
            >
              Загрузить еще
            </button>
          )
        : null}
    </>
  );
};

const ContentLayout = ({ children }: propsType) => {
  return (
    <div className="appartment-scroll pb-[50px] relative">
      {children}
      {<RenderContent />}
    </div>
  );
};

export default ContentLayout;
