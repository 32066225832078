import React from "react";

import ButtonForm from "components/Button/ButtonForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { handleFormatPrice } from "hooks/handleFormatPrice";
import { Project } from "modules/Deal/type";
import { motion } from "framer-motion";
import { Primise } from "enums/primisesType";
import { images } from "utils/resource";
import PencilIcon from "components/constant/icons/PencilIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import { renderImage } from "utils/renderImage";

interface Props {
  item: Project;
  cardClass?: string;
  handleUpdate?: (item: Project) => void;
  handleDelete?: (id: number) => void;
  handleLeftClick?: (id: number) => void;
}

const ProjectPageCard = ({
  item,
  handleUpdate,
  handleDelete,
  handleLeftClick,
}: Props) => {
  const {
    apartment_count,
    brief_description,
    description,
    commercial_count,
    end_date,
    location,
    name,
    parking_count,
    price,
    id,
    storeroom_count,
    photo,
    company_link,
  } = item;

  const date = new Date(end_date);

  const navigate = useNavigate();

  const route = useLocation();

  const options: {} = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const isEditPage = route.pathname === "/projects/edit";

  const humanReadableDate = date.toLocaleDateString("ru-Ru", options);

  const handleNavigate = (type: Primise | null) => {
    if (!isEditPage) {
      if (type) {
        navigate(`/apartments?type=${type}&project_id=${id}`);
        return;
      }
      navigate(`/apartments?project_id=${id}`);
    }
  };

  const handleToggleRecordForShowModal = () => {
    handleLeftClick?.(id);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.6 }}
    >
      <div
        className={`flex flex-col w-full bg-white-default rounded-[8px] text-accent-default overflow-hidden relative sm:flex-row`}
      >
        {isEditPage && (
          <button
            onClick={() => handleDelete?.(item.id)}
            className=" absolute top-[14px] right-[14px] cursor-pointer outline-none"
          >
            <CancelIcon color="#EF3B24" />
          </button>
        )}
        <div className="w-full sm:w-[304px] relative">
          <img
            crossOrigin="anonymous"
            src={renderImage(photo) || images.defaultProjectImg}
            alt="cardPage"
            className={`h-[250px] sm:absolute sm:inset-0 w-full sm:h-full object-cover `}
          />
        </div>

        <div className="p-[20px] sm:flex-1">
          <h3 className=" inline-block text-[16px] font-medium leading-[18.75px]">
            {name}
          </h3>
          <Link to={company_link}>
            <span className="text-[14px] leading-[18px] font-normal ml-[10px] text-blue-default cursor-pointer">
              Подробнее
            </span>
          </Link>

          <p className="text-[14px] leading-[18px] font-normal m-0 p-0 mt-[5px]">
            {location}
          </p>

          <p className=" font-light text-[12px] leading-[16px] mt-[15px]">
            Срок сдачи: {humanReadableDate}
          </p>

          <h1 className="text-[20px] leading-[24px] my-[15px] font-extrabold">
            От {handleFormatPrice(price + "")}
          </h1>

          <p
            className=" font-medium text-[12px] leading-[16px] opacity-50"
            dangerouslySetInnerHTML={{
              __html: brief_description || description,
            }}
          />

          <div className="flex mt-[15px] mb-[10px]">
            <h5
              onClick={() => handleNavigate(Primise.APARTMENT)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {apartment_count} квартиры
            </h5>

            <h5
              onClick={() => handleNavigate(Primise.PARKING)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {parking_count} машино-мест
            </h5>
          </div>

          <div className="flex">
            <h5
              onClick={() => handleNavigate(Primise.STOREROOM)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {storeroom_count} кладовых
            </h5>

            <h5
              onClick={() => handleNavigate(Primise.COMMERCIAL)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {commercial_count} комм. помещений
            </h5>
          </div>
          {isEditPage ? (
            <ButtonForm
              onClick={() => handleUpdate?.(item)}
              text="Редактировать"
              leftIcon={<PencilIcon />}
              className="border border-accent-default py-3 px-6
             hover:bg-accent-default w-full flex justify-center
              hover:text-white-default whitespace-nowrap mt-[30px]"
            />
          ) : (
            <div className="flex gap-[10px] mt-[30px] flex-col md:flex-row">
              <ButtonForm
                onClick={() => handleNavigate(null)}
                text="Выбор помещений"
                className="border border-accent-default py-3 px-6
             hover:bg-accent-default xl:flex-1 flex justify-center
              hover:text-white-default whitespace-nowrap"
              />
              <ButtonForm
                text="Запись на показ"
                onClick={() => handleToggleRecordForShowModal()}
                className="border border-accent-default py-3 px-6
            hover:bg-accent-default xl:flex-1 flex justify-center
            hover:text-white-default whitespace-nowrap
            "
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectPageCard;
