import React, { useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import TextField from "components/Form/TextField/TextField";
import Close from "components/constant/icons/Close";
import SearchIcon from "components/constant/icons/SearchIcon";
import {
  useGlobalSearchQuery,
  useSearchEventsListMutation,
  useSearchNewsListMutation,
  useSearchProjectListMutation,
} from "data/api/global-search";
import { images } from "utils/resource";
import { formatPhoneNumber, isOnlyPhoneNumber } from "hooks/useFormatInput";
import { Link } from "react-router-dom";
import { withDebounce } from "hooks/useDebounce";
import { useSearchClientsListMutation } from "data/api/base-clients";
import { SearchType } from "data/api/global-search/type";

type ModuleName = {
  client: string;
  news: string;
  events: string;
  projects: string;
};

const GlobalSearch = () => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [eventsMutate] = useSearchEventsListMutation();

  const [newsMutate] = useSearchNewsListMutation();

  const [projectsMutate] = useSearchProjectListMutation();

  const [clientsMutate] = useSearchClientsListMutation();

  const [paginationInfo, setPaginationInfo] = useState<
    Record<string, { currPage: number; totalPage: number }>
  >({
    client: { currPage: 1, totalPage: 1 },
    news: { currPage: 1, totalPage: 1 },
    events: { currPage: 1, totalPage: 1 },
    projects: { currPage: 1, totalPage: 1 },
  });

  const [globalSearchResults, setGlobalSearchResult] = useState<
    SearchType<any>[] | null
  >(null);

  const { data, isLoading } = useGlobalSearchQuery(
    searchQuery ? isOnlyPhoneNumber(searchQuery) : "undifined"
  );

  const [isFocused, setIsFocused] = useState(false);

  const hanldeSearch = async (value: string) => {
    await setSearchQuery(value);
  };

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const classEvent = (event.target as any).name;

      setIsFocused([`global-search`].includes(classEvent));
    };

    document.addEventListener("click", handleDocumentClick);

    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  useEffect(() => {
    if (data) {
      setGlobalSearchResult(data.data);
      const updatedPagination = { ...paginationInfo };
      data.meta?.links.forEach((module) => {
        updatedPagination[module.module_name] = {
          currPage: module.currPage,
          totalPage: module.totalPage,
        };
      });
      setPaginationInfo(updatedPagination);
    }
  }, [data]);

  const renderNode = (name: string, data: any) => {
    switch (name) {
      case "news":
        return (
          <Link
            to={`/news/view/${data?.id}`}
            onClick={() => setSearchInput(data?.title)}
          >
            <div>{data?.title}</div>
          </Link>
        );
      case "events":
        return (
          <Link
            to={`/events/view/${data?.id}`}
            onClick={() => setSearchInput(data?.title)}
          >
            <div>{data?.title}</div>
          </Link>
        );
      case "projects":
        return (
          <Link
            to={`/apartments?project_id=${data?.id}`}
            onClick={() => setSearchInput(data?.name)}
          >
            <div>{data?.name}</div>
          </Link>
        );

      case "building":
        return <div>{data?.name}</div>;
      default:
        return <div>Unknown result</div>;
    }
  };

  const module_name: ModuleName = {
    client: "Клиенты",
    news: "Новости",
    events: "Мероприятия",
    projects: "Проекты",
  };

  function handleClear() {
    setSearchInput("");
  }


  const onChange = (value: string) => {
    const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s()+]/g, "");
    setSearchInput(value);
    withDebounce(() => hanldeSearch(filteredValue));
  };

  const handleShowMore = async (moduleName: string) => {
    const currentPage = paginationInfo[moduleName]?.currPage || 1;

    if (moduleName === "client") {
      await clientsMutate(
        `text=${searchInput}&page=${currentPage + 1}&limit=10`
      ).then((newData) => {
        updateGlobalSearchResults(moduleName, newData.data?.data);
        // {currPage: newData.data?.meta?.links.currPage, totalPage: newData.data?.meta?.links.totalPage }
      });
    } else if (moduleName === "projects") {
      await projectsMutate(
        `text=${searchInput}&page=${currentPage + 1}&limit=10`
      ).then((newData) => {
        updateGlobalSearchResults(moduleName, newData.data?.data);
      });
    } else if (moduleName === "news") {
      await newsMutate(
        `text=${searchInput}&page=${currentPage + 1}&limit=10`
      ).then((newData) => {
        updateGlobalSearchResults(moduleName, newData.data?.data);
      });
    } else if (moduleName === "events") {
      await eventsMutate(
        `text=${searchInput}&page=${currentPage + 1}&limit=10`
      ).then((newData) => {
        updateGlobalSearchResults(moduleName, newData.data?.data);
      });
    }

    setPaginationInfo((prev) => ({
      ...prev,
      [moduleName]: {
        ...prev[moduleName],
        currPage: currentPage + 1,
      },
    }));
  };
  const updateGlobalSearchResults = (moduleName: string, newData: any) => {
    console.log("newData", newData);
    console.log("moduleName", moduleName);
    setGlobalSearchResult((prevResults) => {
      if (!prevResults) return [...newData];

      const updatedResults = prevResults.map((module) => {
        if (module.module_name === moduleName) {
          return {
            ...module,
            data: [...module.data, ...newData],
          };
        }
        return module;
      });

      // If module doesn't exist in globalSearchResults yet, add it as a new entry
      if (!updatedResults.some((module) => module.module_name === moduleName)) {
        updatedResults.push(...newData);
      }

      return updatedResults;
    });
  };

  const openMenu = Boolean(searchInput.length && isFocused);

  return (
    <div className={"relative"}>
      <div className="relative md:pb-0">
        <TextField
          name={`global-search`}
          label={""}
          value={searchInput}
          placeholder="Введите текст для поиска"
          onChangeValue={onChange}
          imgLeft={<SearchIcon />}
          className={``}
          classNameInput={` rounded-[30px!important] md:w-[260px!important] md:rounded-[28px!important] py-[6px] pr-[10px]`}
        />
        {searchInput?.length ? (
          <div
            className={
              "search__block__clear absolute top-[50%] right-1 -translate-y-[50%]"
            }
          >
            <ButtonForm
              text={<Close color={"#FF0000"} opacity={"1"} />}
              className={"btn btn-icon bg-white-default"}
              onClick={handleClear}
            />
          </div>
        ) : null}
      </div>
      {openMenu && (
        <div
          className={`md:absolute z-50 top-[calc(100%${"+22px"})] md:max-h-[300px] pb-0 pt-5 md:pt-0 overflow-auto toolbar-scroll left-0 w-full z-10 rounded-bl-lg rounded-br-lg max-h-[70vh] overflow-y-auto toolbar-scroll`}
        >
          <div className="p-0 md:border border-t-0 border-solid border-bg-default bg-white-default">
            {!isLoading ? (
              <>
                {globalSearchResults?.length === 0 && (
                  <p className="text-center py-8">Пусто</p>
                )}
                {globalSearchResults?.map((item) => (
                  <div
                    className=""
                    key={"search__block-item" + item.module_name}
                  >
                    <h3 className="text-sm pt-2 px-4 text-accent-default opacity-50 font-medium">
                      {module_name[item.module_name as keyof ModuleName]}
                    </h3>
                    {item.module_name !== "building" &&
                      item.data?.map((i) => (
                        <div
                          key={i.id}
                          className="px-[15px] py-[8px] cursor-pointer hover:bg-accent-default hover:text-white-default md:border-0 border-t-[1px] border-bg-default"
                        >
                          {item.module_name === "client" ? (
                            <Link
                              to={`/base-clients?id=${i.id}`}
                              onClick={() => setSearchInput(i.fullname)}
                            >
                              <p className={"text-sm font-medium mb-[5px]"}>
                                {i.fullname}
                              </p>
                              <p className={"text-sm font-normal"}>
                                +7 {formatPhoneNumber(i?.phone_number || "")}
                              </p>
                            </Link>
                          ) : (
                            <div className=" font-medium">
                              {renderNode(item.module_name, i)}
                            </div>
                          )}
                        </div>
                      ))}
                    {paginationInfo[item.module_name].currPage <
                      paginationInfo[item.module_name].totalPage && (
                      <button
                        type={"button"}
                        name="global-search"
                        className={
                          "px-6 mx-auto text-blue-default text-sm font-medium flex my-[10px]"
                        }
                        onClick={() => handleShowMore(item.module_name)}
                      >
                        Загрузить еще
                      </button>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className="flex py-4 justify-center">
                <img
                  src={images.loaderImg}
                  alt="loading img"
                  className="animate-spin-slow w-8"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
