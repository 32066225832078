import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "https://login.brusnika.ru",
  realm: "Staging",
  clientId: "aquamarine",
};

const keycloak = new Keycloak(keycloakConfig);

const login = async () => {
  const redirectUri =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/sign-in"
      : "https://dashboard-brusnika.teamtim.tech/sign-in";
  try {
    if (keycloak.didInitialize) {
      await keycloak.login({ prompt: "login", redirectUri });
    } else {
      await keycloak.init({
        checkLoginIframe: false,
        onLoad: "login-required",
        pkceMethod: false,
        redirectUri,
      });
    }
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
};

const logout = async () => {
  const redirectUri =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/sign-in"
      : "https://dashboard-brusnika.teamtim.tech/sign-in";
  try {
    if (keycloak.didInitialize) {
      await keycloak?.logout({ redirectUri });
    } else {
      await keycloak.init({ checkLoginIframe: false });
      await keycloak?.logout({ redirectUri });
    }
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export { keycloak, login, logout };
