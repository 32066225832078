import React, { useEffect, useRef, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import NewsPageCard from "components/Cards/NewsPageCard";
import PlusIcon from "components/constant/icons/PlusIcon";
import { useNavigate } from "react-router";
import { CloseIcon } from "yet-another-react-lightbox";
import Modal from "components/Modal";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import ImgUpload from "components/Form/ImgUpload";
import TextField from "components/Form/TextField/TextField";
import Label from "components/Form/Label";
import Dropdown from "components/Form/Dropdown/Dropdown";
import TegField from "components/Form/TegField";
import { Tags } from "modules/Apartment/ApartmentCardPage/AdvertisementForm";
import ToggleSwitcher from "components/ToggleSwitcher";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import {
  useAddEventMutation,
  useDeleteEventMutation,
  useEditEventMutation,
  useGetEventsAllQuery,
  useToggleIsDraftMutation,
} from "data/api/event";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { eventFormatOptions, eventsCategoryOptions } from "enums/Events";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { Event, InputPair } from "data/api/event/type";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import showToast from "utils/showToast";
import { useUploadFileMutation } from "data/api/file";
import Loader from "components/Loader";
import { useWindowSize } from "hooks/useWindowSize";
import moment from "moment";
import MaskComponent from "components/MaskInput/MaskComponent";
import {
  getIsOpenCreateEvents,
  handleOpenCreateEvents,
} from "store/EvenstReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import NotFound from "components/NotFound";
import TrashIcon from "components/constant/icons/TrashIcon";
import { Editor } from "components/Form/Editor";
import AnimationFrame from "components/AnimationFrame.tsx";

const EventEditPage = () => {
  const navigate = useNavigate();

  const [updateEventData, setUpdateEventData] = useState<Event | null>(null);

  const [wantToDeleteModal, setWantToDeleteModal] = useState(false);

  const [eventId, setEventId] = useState<number>();

  const selectionCity = useSelector((state: RootState) => state.header.city_id);

  const [tegs, setTegs] = useState<{ tags: Tags }>({ tags: [] });

  const size = useWindowSize();

  const contactsRef = useRef<InputPair[]>([{ fullname: "", phone: "" }]);

  const { data, refetch, isLoading } = useGetEventsAllQuery(
    `&city_id=${selectionCity}`
  );

  const res = useGetCitiesQuery();

  const [mutate] = useAddEventMutation();

  const [editMudation] = useEditEventMutation();

  const [fileMutate] = useUploadFileMutation();

  const [deleteMutation] = useDeleteEventMutation();

  const [loading, setLoading] = useState(false);

  const [toggleDraftMutation] = useToggleIsDraftMutation();

  const isCreateEventsItem = useSelector(getIsOpenCreateEvents);

  const cityOptions = useFormatForDropDownOptions(res?.data?.data ?? []);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = useForm<Event>(updateEventData ? { values: updateEventData } : {});

  useEffect(() => {
    if (updateEventData) {
      reset(updateEventData);
      setTegs((prev) => ({ ...prev, tags: updateEventData.tags }));
    }
  }, [updateEventData, reset]);

  async function handleToggleCard(item: Event, value: boolean) {
    await toggleDraftMutation({
      data: { event_id: item.id },
    });
    refetch();
  }

  const dispatch = useDispatch();

  const handleAddInput = () => {
    append({ fullname: "", phone: "" });
  };

  useEffect(() => {
    setValue("city_id", selectionCity);
  }, [isCreateEventsItem]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await fileMutate(formData).unwrap();
        const fileName = response.data.filename;
        setValue("photo", fileName);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const onSubmit: SubmitHandler<Event> = async (data) => {
    setLoading(true);

    if (updateEventData) {
      const formData = {
        ...data,
        max_visitors: +data.max_visitors,
        tags: tegs.tags,
      };
      const { data: res, error } = await editMudation({
        data: {
          data: formData,
          id: updateEventData.id,
        },
      });
      if (res?.data?.id) {
        showToast("События успешно обновлены!", "success", 2000);
        handleCloseModal();
        refetch();
        setLoading(false);
        return;
      }
      if (error?.message) {
        showToast(error?.message, "error", 2000);
        setLoading(false);
        return;
      }
    } else {
      const formData = {
        ...data,
        max_visitors: +data.max_visitors,
        date: moment(
          data.date,
          "ddd MMM DD YYYY HH:mm:ss GMTZZ (zzzz, zzzzz)",
          "Asia/Tashkent"
        ).format("YYYY-MM-DD"),
        tags: tegs.tags,
      };
      const { data: res, error } = await mutate({ data: formData });
      if (res?.data?.id) {
        showToast("Событие успешно создано!", "success", 2000);
        handleCloseModal();
        refetch();
        setLoading(false);
        return;
      }
      if (error?.message) {
        showToast(error?.message, "error", 2000);
        setLoading(false);
        return;
      }
    }
  };

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "contacts",
  });

  useEffect(() => {
    if (!fields.length)
      update(0, {
        fullname: "",
        // id: Math.floor(Math.random() * 100),
        phone: "",
      });
  }, [fields]);

  const handleDeleteEvent = async () => {
    if (eventId) {
      const { error } = await deleteMutation(eventId);
      if (error?.message) {
        showToast(error.message, "error", 2000);
        return;
      }
      showToast("Успешно удалено!", "success", 2000);
      refetch();
      setWantToDeleteModal(false);
    }
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return updateEventData ? opts?.[0] : null;
  };

  const dateTimeOptions = [
    {
      label: "10:00",
      value: "10:00",
    },
    {
      label: "10:30",
      value: "10:30",
    },
    {
      label: "11:00",
      value: "11:00",
    },
    {
      label: "11:30",
      value: "11:30",
    },
    {
      label: "12:00",
      value: "12:00",
    },
    {
      label: "12:30",
      value: "12:30",
    },
    {
      label: "13:00",
      value: "13:00",
    },
    {
      label: "13:30",
      value: "13:30",
    },
    {
      label: "14:00",
      value: "14:00",
    },
    {
      label: "14:30",
      value: "14:30",
    },
    {
      label: "15:00",
      value: "15:00",
    },
    {
      label: "15:30",
      value: "15:30",
    },
    {
      label: "16:00",
      value: "16:00",
    },
    {
      label: "16:30",
      value: "16:30",
    },
    {
      label: "17:00",
      value: "17:00",
    },
    {
      label: "17:30",
      value: "17:30",
    },
    {
      label: "18:00",
      value: "18:00",
    },
  ];

  const handleOpenModal = () => {
    handleResetForm();
    dispatch(handleOpenCreateEvents(true));
  };

  const handleCloseModal = () => {
    if (updateEventData) {
      setUpdateEventData(null);
      handleResetForm();
    }
    dispatch(handleOpenCreateEvents(false));
  };

  const handleResetForm = () => {
    reset({
      title: "",
      phone: "",
      city_id: 0,
      contacts: [],
      description: "",
      location: "",
      date: "",
      start_time: "",
      end_time: "",
      leader: "",
      is_liked: false,
      is_banner: false,
      type: undefined,
      max_visitors: 0,
      views_count: 0,
      likes_count: 0,
      is_draft: false,
    });
    // setContacts([{ fullname: "", phone: "" }]);
    contactsRef.current = [];
    setTegs((prev) => ({ ...prev, tags: [] }));
  };

  return (
    <section>
      <div className={"bg-bg-default"}>
        <AnimationFrame>
          <div className="br-container py-[20px] relative min-h-[50vh]">
            {isLoading && <Loader />}
            <div className={"flex items-center justify-between mb-[15px]"}>
              <h1
                className={
                  "text-[18px] sm:text-[25px] font-dewi font-extrabold"
                }
              >
                {size.isLg ? " Редактор мероприятий" : "События Брусники"}
              </h1>

              <ButtonForm
                leftIcon={<PlusIcon />}
                text={"Создать"}
                onClick={handleOpenModal}
                className={
                  "p-3 bg-accent-default hidden rounded text-white-default max-w-[259px] w-full lg:flex justify-center"
                }
              />
            </div>

            <div className={"mt-[18px]"}>
              <div
                className={
                  "grid grid-cols-1 gap-[15px] sm:grid-cols-2 lg:grid-cols-3  md:mt-[20px] mt-[15px] xl:mt-[30px]"
                }
              >
                {(data?.data?.length &&
                  data.data.map((item) => (
                    <NewsPageCard
                      type="event"
                      key={`news-block-item-${item.id}`}
                      imageSrc={
                        process.env.REACT_APP_FILES_URL + "/" + item?.photo ||
                        ""
                      }
                      item={item}
                      title={item.title}
                      watched={item.views_count}
                      likes={item.likes_count}
                      date={`${
                        item.date ? moment(item.date).format("DD.MM.YYYY") : ""
                      } ${item.start_time || ""}`}
                      onClick={(event) => {
                        if (
                          ["button", "checkbox", "toggler"].includes(
                            event.target.type
                          )
                        )
                          return;

                        navigate(`/events/view/${item?.id}`);
                      }}
                      edit={{
                        toggle: {
                          isToggle: item.is_draft,
                          setIsToggle: (value: boolean) =>
                            handleToggleCard(item, value),
                        },
                        editNews: (item: Event) => {
                          setUpdateEventData(item);
                          dispatch(handleOpenCreateEvents(true));
                        },
                        deleteNews: (id: number) => {
                          setEventId(id);
                          setWantToDeleteModal(true);
                        },
                      }}
                    />
                  ))) ||
                  null}
              </div>
              {!data?.data?.length && !isLoading && (
                <>
                  <NotFound title="Мероприятия не найдены" />
                </>
              )}
            </div>
            <div className=" hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
              <div
                className={
                  "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
                }
                onClick={() => navigate("/events")}
              >
                <CloseIcon color={"#FFFFFF"} />
              </div>
            </div>
          </div>
        </AnimationFrame>
      </div>

      {/* Create and Edit Modal */}
      <Modal
        isOpen={isCreateEventsItem}
        className="mx-auto w-full sm:w-[672px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>
            {updateEventData ? "Редактировать" : "Создать"}{" "}
          </h1>
          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={handleCloseModal}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-[20px] pl-[1px] overflow-y-scroll toolbar-scroll max-h-[70vh] pr-3"
        >
          <Controller
            name="photo"
            control={control}
            rules={{
              required: !!getValues("photo"),
            }}
            render={({ field: { value } }) => (
              <ImgUpload
                value={
                  value ? `${process.env.REACT_APP_FILES_URL}/${value}` : ""
                }
                handleImageUpload={handleImageUpload}
                title="Обложка"
                requirementText="Добавьте изображение с разрешением минимум 1920х1080 px"
              />
            )}
          />

          <Controller
            name="title"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Напишите заголовок мероприятия"
                label="Заголовок"
              />
            )}
          />
          <Label label="Описание" className={"w-full"}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Editor value={value} onChangeValue={onChange} />
                // <QuillEditor value={value} onChangeValue={onChange} />
                // I return back to old Editor because, Backend Developer working on it,  he will fix image upload
              )}
            />
          </Label>
          <div className="flex flex-col sm:flex-row gap-5 sm:gap-[10px]">
            <Label label="Категория" className="flex-1">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={eventsCategoryOptions}
                    value={getValue(eventsCategoryOptions, value)}
                    name="type"
                    placeholder="Выберите категорию "
                  />
                )}
              />
            </Label>
            <Label label="Город" className="flex-1">
              <Controller
                name="city_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={cityOptions}
                    value={getValue(cityOptions, value)}
                    name="city_id"
                    placeholder="Выберите Город"
                  />
                )}
              />
            </Label>
          </div>
          <div className="flex flex-col sm:flex-row gap-5 sm:gap-[10px]">
            <Label label="Формат мероприятия" className="flex-1">
              <Controller
                name="format"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={eventFormatOptions}
                    value={getValue(eventFormatOptions, value)}
                    name="format"
                    placeholder="Выберите формат мероприятия"
                  />
                )}
              />
            </Label>
            <Controller
              name="max_visitors"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  type="number"
                  minLength={0}
                  maxLength={200}
                  onChange={onChange}
                  placeholder="Введите число участников"
                  label="Максимальное кол-во участников"
                  className="flex-1"
                  name="max_visitors"
                />
              )}
            />
          </div>

          <Label label="Дата" className={"w-full"}>
            <Controller
              rules={{
                required: true,
              }}
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerField
                  className={`w-full ${!size.isMd && "calendar-block"}`}
                  placeholder={"Выберите дату"}
                  value={value ? value : ""}
                  minDate={moment().toDate()}
                  isIcon={!size.isMd}
                  onChangeDate={onChange}
                />
              )}
            />
          </Label>

          <div className={"flex gap-[10px]"}>
            <Label label="Время начала" className={"w-full"}>
              <Controller
                rules={{
                  required: true,
                }}
                name="start_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    options={dateTimeOptions.slice(0, -1)}
                    value={getValue(dateTimeOptions, value)}
                    name="start_time"
                    placeholder="Выберите Время"
                  />
                )}
              />
            </Label>
            <Label label="Время окончания" className={"w-full"}>
              <Controller
                rules={{
                  required: true,
                }}
                name="end_time"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const startTime = watch("start_time");

                  const startTimeIndex = dateTimeOptions.findIndex(
                    (option) => option.value === startTime
                  );

                  const endOptions =
                    startTimeIndex !== -1
                      ? dateTimeOptions.slice(startTimeIndex + 1)
                      : dateTimeOptions;

                  return (
                    <Dropdown
                      disabled={!startTime}
                      onChange={(e) => onChange(e.value)}
                      options={endOptions}
                      value={getValue(dateTimeOptions, value)}
                      name="end_time"
                      placeholder="Выберите Время"
                    />
                  );
                }}
              />
            </Label>
          </div>
          <TegField
            tegsStateForm={tegs?.tags}
            setTegsStateForm={setTegs}
            placeholder="Введите хэштэг"
            label="Введите хэштэг"
          />
          <Controller
            name="leader"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Напишите имя ведущего"
                label="Ведущий"
              />
            )}
          />
          <Controller
            name="location"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Напишите адрес"
                label="Адрес"
              />
            )}
          />

          <div className=" border border-dashed border-spacing-5 flex flex-col gap-6 border-blue-default p-[15px] rounded-[4px]">
            {fields.map((input, index) => (
              <div className="flex flex-col sm:flex-row gap-[10px]" key={index}>
                <Controller
                  name={`contacts.${index}.fullname`}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onChange={(e) => onChange(e)}
                      value={value}
                      placeholder="Имя"
                      label="Имя"
                      className="flex-1"
                    />
                  )}
                />
                <Label label="Номер телефона" className="flex-1">
                  <Controller
                    rules={{
                      required: true,
                    }}
                    name={`contacts.${index}.phone`}
                    control={control}
                    render={({ field }) => (
                      <MaskComponent {...field} imgLeft={<PhoneIcon />} />
                    )}
                  />
                </Label>
                {index !== 0 && (
                  <button
                    disabled={index === 0}
                    type="button"
                    onClick={() => remove(index)}
                    className={`text-red-500 flex items-center mt-4 ${
                      size.isSm &&
                      "w-full p-2 border border-red-default rounded-md justify-center mt-0"
                    }`}
                    aria-label="Delete contact"
                  >
                    <TrashIcon color="#FF0000" />{" "}
                  </button>
                )}
              </div>
            ))}

            <div
              onClick={handleAddInput}
              className="border border-dashed border-blue-default h-12 mt-4 cursor-pointer rounded-[4px] flex items-center justify-center"
            >
              <PlusIcon color="#3583FF" />
            </div>
          </div>
          {/* <Controller
            name="is_liked"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                setIsToggle={onChange}
                isToggle={value}
                label="Лайк"
              />
            )}
          /> */}
          <Controller
            name="is_banner"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                setIsToggle={onChange}
                isToggle={value}
                label="Отображать на баннере"
              />
            )}
          />
          <Controller
            name="is_draft"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                setIsToggle={onChange}
                isToggle={value}
                label="Сделать как шаблон "
              />
            )}
          />
          <Controller
            name="push_notification"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                setIsToggle={onChange}
                isToggle={value}
                label="Отправить пуш всем пользователям"
              />
            )}
          />
          <div className="flex flex-col sm:flex-row gap-[10px] mt-[10px]">
            <ButtonForm
              onClick={handleCloseModal}
              text="Назад"
              type="button"
              className="py-3 px-6 bg-bg-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
            />
            <ButtonForm
              disabled={!isValid || loading}
              type="submit"
              text={updateEventData ? "Сохранить" : "Создать"}
              className="py-3 px-6 border bg-accent-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-accent-disabled hover:text-bg-default"
            />
          </div>
        </form>
      </Modal>
      {/* Want to delete Modal */}
      <Modal
        isOpen={wantToDeleteModal}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
      >
        <div className={"flex  mb-[20px] items-center justify-center"}>
          <div className="text-center text-accent-default">
            <h1 className={"font-extrabold text-[20px] font-dewi"}>Удаление</h1>
            <p className="text-sm font-normal">
              Вы уверены что хотите удалить мероприятие?
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-[10px] mt-[10px]">
          <ButtonForm
            text="Отмена"
            onClick={() => setWantToDeleteModal(false)}
            className="py-3 px-6 bg-white-default border border-accent-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
          />
          <ButtonForm
            onClick={handleDeleteEvent}
            text="Да"
            className="py-3 px-6  bg-red-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-red-focus hover:text-bg-default"
          />
        </div>
      </Modal>
    </section>
  );
};

export default EventEditPage;
