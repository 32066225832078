import React, { useEffect, useState } from "react";

import AuthPageWraper from "../../../components/AuthPageWraper";
import { AgentForm } from "./AgentForm";
import { EmployeeForm } from "./EmployeeForm";
import SelectionToggle from "../../../components/SelectionToggle";
import ButtonForm from "components/Button/ButtonForm";
import { LoginRoles } from "enums/authEnums";
import { useSignInEmployeeWithSSOMutation } from "data/api/auth";
import showToast from "utils/showToast";
import Loader from "components/Loader";

const Login = () => {
  // @TODO change SelectionToggle and remove loginProcess state make loginRole nullable
  // it helps to use one useState instead of two
  const [loginRole, setLoginRole] = useState(LoginRoles.AGENT);

  const [loginProcess, setLoginProcess] = useState("role");

  const [signInWithSSOMutate, { isLoading }] =
    useSignInEmployeeWithSSOMutation();

  const handleLoginRoleChange = (role: LoginRoles) => {
    setLoginRole(role);
  };
  const handleLoginProcessChange = () => {
    setLoginProcess("role");
  };

  useEffect(() => {
    const url = window.location.href;
    const hashPart = url.split("#")[1];
    if (hashPart) {
      const params = new URLSearchParams(hashPart);
      const code = params.get("code");
      console.log("Code:", code);
      if (code) {
        login(code);
      }
    }
  }, []);

  async function login(code: string) {
    try {
      const { data: res, error } = await signInWithSSOMutate({
        data: {
          code: code,
          redirectUri:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/sign-in"
              : "https://dashboard-brusnika.teamtim.tech/sign-in",
        },
      });
      if (res?.data.accessToken) {
        localStorage.setItem("token", res?.data.accessToken as string);
        window.location.href = "/";
      }
      if (error?.message) {
        showToast(error.message, "error", 4000);
        // window.location.href = "/sign-in";
      }
    } catch (error) {}
  }

  return (
    <AuthPageWraper isRegisterPage={true}>
      <h3 className="text-center text-accent-default font-extrabold text-[25px] font-dewi m-0 mb-[15px]">
        Авторизация
      </h3>
      {isLoading ? (
        <div className="min-h-[200px] relative">
          <Loader />
        </div>
      ) : (
        <>
          {loginProcess === "role" ? (
            <SelectionToggle
              hasButton={true}
              leftLabel="Агент"
              rightLabel="Сотрудник"
              options={{ LEFT: LoginRoles.AGENT, RIGHT: LoginRoles.EMPLOYEE }}
              selectedOption={loginRole}
              onSubmit={setLoginProcess}
              handleChange={handleLoginRoleChange}
              buttonText="Войти"
              title="Выберите свою роль"
            />
          ) : (
            <LoginForm
              goBack={handleLoginProcessChange}
              loginRole={loginRole}
            />
          )}
        </>
      )}
    </AuthPageWraper>
  );
};

function LoginForm({
  loginRole,
  goBack,
}: {
  loginRole: LoginRoles;
  goBack: () => void;
}) {
  return (
    <>
      {loginRole === LoginRoles.AGENT ? <AgentForm /> : <EmployeeForm />}
      <div className="flex flex-col gap-[10px] mt-[10px]">
        <ButtonForm
          className="py-[12px] w-full
              text-accent-default text-center flex justify-center bg-bg-default
               hover:bg-accent-default
               hover:text-white-default"
          text="Изменить роль"
          onClick={goBack}
        />
      </div>
    </>
  );
}

export default Login;
