import React, { useEffect, useState } from "react";
import ApartmentCard from "./ApartmentCard";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleBookingModal,
  toggleItemInStore,
  toggleRecordForShowModal,
} from "store/ApartmentReducer";
import ChessList from "./ChessList";
import { RootState } from "store";
import {
  Apartment,
  groupByProjectName,
  transformData,
} from "./dataTransformer";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import AnimationFrame from "components/AnimationFrame.tsx";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import BookingFrom from "components/BookingAndVisits/BookingFrom";
import { useGetPremisesQuery } from "data/api/premises";
import ExchangeModalApplicationPhone from "modules/Exchange/ExchangeModalApplicationPhone";
import NotFound from "components/NotFound";
import { useLocation } from "react-router";
import BackIcon from "../../../components/constant/icons/BackIcon";
import { iFilterDeals } from "../../Deal";
import { useWindowSize } from "hooks/useWindowSize";

interface porpsType {
  data: Apartment[];
  isLoading: boolean;
}

const ApartmentList: React.FC<porpsType> = ({ data, isLoading }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const buildingId = queryParams.get("building_id");
  const projectId = queryParams.get("project_id");

  const selection = useSelector((state: RootState) => state.apartment);

  const [activeSection, setActiveSection] = useState<string>("");

  const [sectionSelect, setSectionSelect] = useState<Apartment[]>([]);

  const [clickedItem, setClikedItem] = useState<Apartment | null>(null);

  const [isASCPrice, setIsASCPrice] = useState(true);

  const [isASCSquare, setIsASCSquare] = useState(true);

  const [filter, setFilter] = useState<iFilterDeals>({
    price: "ASC",
    size: "ASC",
  });

  const { isSm } = useWindowSize();

  const [sortList, setSortList] = useState(data);

  const [applicationPhoneModalOpen, setApplicationPhoneModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const { refetch } = useGetPremisesQuery(location.search.slice(1));

  const sortBySectionName = Object.entries(
    transformData(sortList, "name") || {}
  ).sort((a, b) => {
    const getNumber = (name: string): number => {
      const match = name.match(/\d+/);
      return match ? parseInt(match[0], 10) : 0;
    };
    return getNumber(a[0]) - getNumber(b[0]);
  });

  useEffect(() => {
    const transformedData = transformData(data, "name");
    const firstSection = Object.keys(transformedData)?.[0];
    if (firstSection) {
      setActiveSection(firstSection);
      setSectionSelect(transformedData[firstSection]);
    } else {
      setSectionSelect([]);
    }
    setSortList(data);
  }, [data]);

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const handleSectionClick = (sectionName: string, apartments: Apartment[]) => {
    setActiveSection(sectionName);
    setSectionSelect(apartments);
  };

  const handleClickBooking = (item: Apartment | null) => {
    if (item) {
      dispatch(toggleBookingModal(item.id));
      setClikedItem(item);
    }
  };

  const handleClickRecord = (item: Apartment | null) => {
    if (selection.recordForShowModalById === item?.id) {
      setClikedItem(null);
      dispatch(toggleRecordForShowModal(item.id));
    } else if (item) {
      setClikedItem(item);
      dispatch(toggleRecordForShowModal(item.id));
    }
  };

  const handleClickAppLicationPhone = () => {
    setApplicationPhoneModalOpen(true);
  };

  const handleOrderData = (
    key: string,
    is: boolean,
    set: (val: boolean) => void
  ) => {
    set(!is);
    setFilter((prev: any) => ({ ...prev, [`${key}`]: !is ? "ASC" : "DESC" }));

    const sortedData = [...data].sort((a: any, b: any) => {
      if (filter[key]) {
        const order = filter[key] === "ASC" ? -1 : 1;

        if (a[key] !== b[key]) {
          return (a[key] - b[key]) * order;
        }
      }
      return 0;
    });

    setSortList(sortedData);
  };


  return (
    <div className="py-[20px] overflow-x-hidden relative min-h-[50vh]">
      {isLoading && <Loader />}
      {selection.chess ? (
        <>
          {sortBySectionName?.length ? (
            <PageTitle
              text={
                (projectId && Number(projectId) != 0
                  ? sortBySectionName?.[0]?.[1]?.[0]?.project?.name + ""
                  : "") +
                (buildingId && Number(buildingId) != 0
                  ? `,  ${sortBySectionName?.[0]?.[1]?.[0]?.building.name}`
                  : "")
              }
            />
          ) : null}

          <div className="flex gap-[20px] mt-[20px] mb-[15px]">
            {sortList?.length ? (
              sortBySectionName?.map(([sectionName, apartments]) => (
                <li
                  onClick={() => handleSectionClick(sectionName, apartments)}
                  key={sectionName}
                  className={`text-accent-default cursor-pointer list-none underline font-medium text-[16px] ${
                    activeSection === sectionName ? "text-blue-default" : ""
                  }`}
                >
                  {!isNaN(Number(sectionName))
                    ? `Секция  ${sectionName}`
                    : sectionName}
                </li>
              ))
            ) : (
              <div className="mx-auto">
                <NotFound />
              </div>
            )}
          </div>
          <ChessList data={sectionSelect} />
          <div className="absolute w-[50px] sm:w-[120px] right-0 bottom-[30px] top-0 opacity-70 bg-custom-gradient pointer-events-none "></div>
        </>
      ) : (
        <>
          {sortList?.length
            ? Object.entries(groupByProjectName(sortList) || {})?.map(
                ([projectName, apartments]) => (
                  <div key={projectName}>
                    {!isSm && <PageTitle text={projectName} />}
                    <div
                      className={"flex absolute top-0 md:right-0 md:top-[20px]"}
                    >
                      <button
                        className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center
												justify-center gap-1 bg-transparent text-sm"
                        onClick={() =>
                          handleOrderData("price", isASCPrice, setIsASCPrice)
                        }
                      >
                        Цена
                        <div
                          className={`p-[7px] ${
                            isASCPrice ? "-rotate-90" : "rotate-90"
                          } `}
                        >
                          <BackIcon />
                        </div>
                      </button>
                      <button
                        className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center
												justify-center gap-1 bg-transparent text-sm"
                        onClick={() =>
                          handleOrderData("size", isASCSquare, setIsASCSquare)
                        }
                      >
                        Площадь
                        <div
                          className={`p-[7px] ${
                            isASCSquare ? "-rotate-90" : "rotate-90"
                          } `}
                        >
                          <BackIcon />
                        </div>
                      </button>
                    </div>
                    <AnimationFrame>
                      <div className=" mt-[30px] mb-[30px] grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-5">
                        {apartments?.map((item: Apartment) => (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 1 }}
                          >
                            <ApartmentCard
                              handleSelectItem={handleSelectItem}
                              handleClickBooking={handleClickBooking}
                              handleClickRecord={handleClickRecord}
                              handleClickAppLicationPhone={
                                handleClickAppLicationPhone
                              }
                              item={item}
                              key={item.id}
                            />
                          </motion.div>
                        ))}
                      </div>
                    </AnimationFrame>
                  </div>
                )
              )
            : !isLoading && (
                <div className="mt-[20px]">
                  <NotFound />
                </div>
              )}
        </>
      )}
      <RecordForShow
        project_id={clickedItem?.project?.id}
        handleToggle={() => handleClickRecord(clickedItem)}
        isOpenModal={selection.recordForShowModalById === clickedItem?.id}
      />
      <BookingFrom
        isOpen={selection.recordBookingById === clickedItem?.id}
        primise_id={clickedItem?.id}
        handleToggle={() => handleClickBooking(clickedItem)}
        refetch={refetch}
      />
      <ExchangeModalApplicationPhone
        isOpenModal={applicationPhoneModalOpen}
        setIsOpenModal={setApplicationPhoneModalOpen}
      />
    </div>
  );
};

export default ApartmentList;
