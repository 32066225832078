import eventsImg from "../assets/images/eventsImg.png";
import cardImg from "../assets/images/CardImg.png";
import profileImg from "../assets/images/profileImg.png";
import apartmentImg from "../assets/images/flatImgLittle.png";
import apartmentImg2 from "../assets/images/Apartment.png";
import parkingImg from "../assets/images/parkingImg.png";
import storeroomImg from "../assets/images/storeroomImg.png";
import carouselImg from "../assets/images/carouselImg.png";
import newsImage from "../assets/images/newsImage.png";
import profileImg2 from "../assets/images/profileImg2.png";
import apartmentCardImg from "../assets/images/apartment-card/1.png";
import apartmentCardImg2 from "../assets/images/apartment-card/2.png";
import apartmentCardImg3 from "../assets/images/apartment-card/3.png";
import apartmentDefaultImg from "../assets/images/apartment-card/apartmentDefaultImg.png";
import apartmentCardImg4 from "../assets/images/apartment-card/4.png";
import apartmentCardImg5 from "../assets/images/apartment-card/5.png";
import compass from "../assets/images/apartment-card/compass.png";
import layoutLocation from "../assets/images/apartment-card/layout.png";
import defaultImg from "../assets/images/profileIm.png";
import eventsCarousel from "../assets/images/Events/EventsCarousel.png";
import Maketbg1 from "../assets/images/apartment-card/bg1.png";
import Maketbg2 from "../assets/images/apartment-card/bg2.png";
import Maketbg3 from "../assets/images/apartment-card/bg3.png";
import Maketbg4 from "../assets/images/apartment-card/bg4.png";
import SemantecLogo from "../assets/images/SemantecLogo.png";
import loaderImg from "../assets/images/Loader.png";
import brusnika_logo from "../assets/images/brusnika_logo.png";
import eventsImgAnalitics1 from "../assets/images/analitics/eventsImg1.png";
import defaultProjectImg from "../assets/images/defaultProjectImg.png";
import notFound from "../assets/images/notFound-png.png";
import noPhoto from "../assets/images/Events/no-image.png";

export const images = {
  eventsImg,
  cardImg,
  profileImg,
  apartmentImg,
  apartmentImg2,
  parkingImg,
  storeroomImg,
  carouselImg,
  newsImage,
  profileImg2,
  apartmentCardImg,
  apartmentCardImg2,
  apartmentCardImg3,
  apartmentCardImg4,
  apartmentCardImg5,
  compass,
  layoutLocation,
  defaultImg,
  eventsCarousel,
  Maketbg1,
  Maketbg2,
  Maketbg3,
  Maketbg4,
  SemantecLogo,
  loaderImg,
  brusnika_logo,
  eventsImgAnalitics1,
  notFound,
  defaultProjectImg,
  apartmentDefaultImg,
  noPhoto,
};
