import React from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import { Controller, useForm } from "react-hook-form";
import { SearchField } from "../../components/Form/SearchField";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import DatePickerField from "../../components/Form/DatePicker/DatePickerField";
import Modal from "../../components/Modal";
import { useFormatForDropDownOptions } from "../../hooks/useDropDownOption";
import { useGetProjectsQuery } from "../../data/api/project";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useCreateTasksMutation } from "data/api/tasks";
import { ApiException, ResponseStatusType } from "../../data/api/exception";
import showToast from "../../utils/showToast";
import { apiService } from "data/api";
import TextField from "../../components/Form/TextField/TextField";
import ClockIcon from "../../components/constant/icons/ClockIcon";
import DatePickerTimeField from "../../components/Form/DatePicker/DatePickerTimeField";

interface TasksAddModalProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  refetch: () => void;
}

interface AddForm {
  manager_id: number;
  comment: string;
  task_type: string;
  client_id: number;
  project_id: number;
  premise_id: number;
  lead_id: number;
  end_date: string;
  start_date?: string;
  time?: string;
}

const TasksAddModal = ({ isOpen, onClose, refetch }: TasksAddModalProps) => {
  const { isMd } = useWindowSize();

  const selection = useSelector((state: RootState) => state);

  const { data: projects } = useGetProjectsQuery(selection.header.city_id);

  const [mutate] = useCreateTasksMutation();

  const dispatch = useDispatch();

  const optionsProject = useFormatForDropDownOptions(projects?.data ?? []);

  const typeofTaskOption = [
    { label: "Первичный показ", value: "первичный показ" },
    { label: "Повторный показ", value: "повторный показ" },
    { label: "Звонок клиенту", value: "звонок клиенту" },
    { label: "Иное", value: "иное" },
  ];

  const statusOption = [
    { value: 0, label: "Создана" },
    { value: 1, label: "В работе" },
    { value: 2, label: "Завершена" },
    { value: 3, label: "Отменена" },
  ];

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
    getValues,
  } = useForm<AddForm>();

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  async function onSubmit(obj: AddForm) {
    const requestObj = {
      ...obj,
      lead_id: Number(obj.lead_id),
    };

    try {
      const { data: res, error } = await mutate(requestObj);
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        reset();
        onClose(false);
        showToast("Задача добавлена", "success", 1000);
        refetch();
        return;
      }

      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
    } catch (error) {
      const err = error as ApiException;
      console.log(err);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      className="mx-auto w-full sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
    >
      <div className={"flex justify-between mb-[20px]"}>
        <h1 className={"font-extrabold text-[25px] font-dewi"}>
          Добавление задачи
        </h1>

        <ButtonForm
          text={<CloseWithBorderIcon />}
          onClick={() => {
            reset();
            onClose(false);
          }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 h-[50vh] pl-[1px] overflow-y-auto toolbar-scroll pr-3 mb-5">
          <Controller
            name="lead_id"
            control={control}
            rules={{
              required: "Номер сделки обязателен для заполнения",
              validate: (value) => {
                if (value && !/^\d+$/.test(value.toString())) {
                  return "Номер сделки должен быть числом";
                }
                return true;
              },
            }}
            render={({ field: { onChange, value, disabled } }) => (
              <TextField
                name="lead_id"
                disabled={disabled}
                value={value}
                onChange={onChange}
                label="Номер сделки"
                placeholder="Введите номер сделки"
                errors={errors}
              />
            )}
          />

          <div className="relative">
            <Label label="Исполнитель">
              <Controller
                name="manager_id"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Исполнитель обязателен для заполнения",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <SearchField
                      // selectItem={value}
                      onChange={(value) => onChange(value?.id)}
                      isShowBtnDelete={true}
                      className={
                        "min-w-[175px] xl:min-w-[238px] md:rounded-[4px!important]"
                      }
                      placeholder={"Исполнитель не выбран"}
                      id={"manager_id"}
                      isSearchUsers
                      imgLeft={""}
                    />

                    {errors?.["manager_id"] && (
                      <p className="text-red-default mb-[5px] text-xs">
                        {errors?.["manager_id"]?.message}
                      </p>
                    )}
                  </>
                )}
              />
            </Label>
          </div>

          <Label label="Клиент">
            <Controller
              name="client_id"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Клиент обязателен для заполнения",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <SearchField
                    // selectItem={value}
                    onChange={(value) => onChange(value?.id)}
                    isShowBtnDelete={true}
                    id={"client_id"}
                    placeholder={"Клиент не выбран"}
                    className={
                      "min-w-[175px] xl:min-w-[238px] md:rounded-[4px!important]"
                    }
                    imgLeft={""}
                  />

                  {errors?.["client_id"] && (
                    <p className="text-red-default mb-[5px] text-xs">
                      {errors?.["client_id"]?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Label>

          <Label label="Тип задачи">
            <Controller
              name="task_type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Тип задачи обязателен для заполнения",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  name="task_type"
                  value={getValue(typeofTaskOption, value)}
                  onChange={(value) => onChange(value.value)}
                  options={typeofTaskOption}
                  placeholder="Тип задачи не выбран"
                  errors={errors}
                />
              )}
            />
          </Label>

          {/*<Label label="Статус">*/}
          {/*  <Controller*/}
          {/*    name="lead_id"*/}
          {/*    control={control}*/}
          {/*    rules={{*/}
          {/*      required: {*/}
          {/*        value: true,*/}
          {/*        message: "Статус обязателен для заполнения",*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    render={({ field: { value, onChange } }) => (*/}
          {/*      <Dropdown*/}
          {/*        name="lead_id"*/}
          {/*        value={getValue(statusOption, value)}*/}
          {/*        onChange={(value) => onChange(value.value)}*/}
          {/*        options={statusOption}*/}
          {/*        placeholder="Статус не выбран"*/}
          {/*        errors={errors}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  />*/}
          {/*</Label>*/}

          <Label label="Проект">
            <Controller
              name="project_id"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Проект обязателен для заполнения",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  name="type"
                  value={getValue(optionsProject, value)}
                  onChange={(value) => onChange(value.value)}
                  options={optionsProject}
                  placeholder="Проект не выбран"
                  errors={errors}
                  isSearchable
                />
              )}
            />
          </Label>

          <Label label="Дедлайн" className={"w-full"}>
            <div className={"flex gap-3"}>
              <Controller
                name="end_date"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Дата обязательна для заполнения",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <DatePickerField
                    name="type"
                    startDate={value ? new Date(value) : null}
                    onChangeDate={(date) => onChange(date?.toISOString())}
                    className={`w-full ${!isMd && "calendar-block left"}`}
                    minDate={new Date()}
                    placeholder="Выберите дату"
                    isIcon
                    isRightIcon
                    isClearable
                    isMobile={isMd}
                    errors={errors}
                  />
                )}
              />
              <Controller
                name="end_date"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Время обязательна для заполнения",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <DatePickerTimeField
                    onChangeDate={(date) => onChange(date?.toISOString())}
                    startDate={value ? new Date(value) : null}
                    placeholder={"00:00"}
                  />
                )}
              />
            </div>
          </Label>

          <Label label="Комментарий">
            <Controller
              name="comment"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Комментарий обязателен для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <textarea
                    value={value}
                    onChange={onChange}
                    name="comment"
                    rows={3}
                    placeholder="Введите свой комментарий"
                    cols={5}
                    className="border border-boder-default py-3 px-4 w-full rounded text-sm font-normal outline-none resize-none"
                  ></textarea>

                  {errors?.["comment"] && (
                    <p className="text-red-default mb-[5px] text-xs">
                      {errors?.["comment"]?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Label>
        </div>

        <div className="flex flex-col  gap-[10px]">
          <ButtonForm
            type={"submit"}
            className={
              " px-6 py-3 w-full justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Добавить"
          />
          <ButtonForm
            onClick={() => {
              reset();
              onClose(false);
            }}
            className={
              " px-6 py-3 w-full bg-bg-default text-accent-default justify-center hover:bg-accent-default text-white-defaul  hover:text-white-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Назад"
          />
        </div>
      </form>
    </Modal>
  );
};

export default TasksAddModal;
