import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Modal from "components/Modal";
import DateIcon from "components/constant/icons/DateIcon";
import CopyIcon from "../../components/constant/icons/CopyIcon";
import showToast from "../../utils/showToast";
import MortgageModal from "../Calculator/MortgageModal";
import TasksAddModal from "./TasksAddModal";
import { TaskgetOneType } from "data/api/tasks/type";
import useFormattedDate from "hooks/useFormatDate";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { useFinishTaskMutation } from "data/api/tasks";
import { ResponseStatusType } from "data/api/exception";

interface Props {
  isTaskViewModalOpen: boolean;
  setTaskViewModalOpen: Dispatch<SetStateAction<boolean>>;
  handleCompleteTheTask: () => void;
  taskResultOpen: boolean;
  setTaskResultOpen: Dispatch<SetStateAction<boolean>>;
  taskAddModalOpen: boolean;
  setTaskAddModalOpen: Dispatch<SetStateAction<boolean>>;
  mortgageModalOpen: boolean;
  handleApplyForMortgage: () => void;
  appliedModal: boolean;
  setAppliedModal: Dispatch<SetStateAction<boolean>>;
  task?: TaskgetOneType;
  isLoading: boolean;
  refetch: () => void;
}

const TasksModals = ({
  isTaskViewModalOpen,
  taskResultOpen,
  taskAddModalOpen,
  mortgageModalOpen,
  appliedModal,
  handleApplyForMortgage,
  setTaskViewModalOpen,
  handleCompleteTheTask,
  setTaskResultOpen,
  setTaskAddModalOpen,
  setAppliedModal,
  refetch,
  task,
}: Props) => {
  const copyAreaRef = useRef<HTMLDivElement>(null);

  const [resultComment, setResultComment] = useState<string>("");

  const [finishTaskMutation] = useFinishTaskMutation();

  const formateDate = useFormattedDate();

  function toUpperCasefunc(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const clientData = [
    { id: 1, property: "ФИО клиента:", value: task?.client?.fullname },
    {
      id: 2,
      property: "Телефон клиента:",
      value: "+7" + " " + formatPhoneNumber(task?.client?.phone_number || ""),
    },
    {
      id: 3,
      property: "Почта клиента:",
      value: task?.client?.email || "",
    },
    {
      id: 4,
      property: "Начало и окончание задачи:",
      value: [
        formateDate(task?.start_date || "", false),
        formateDate(task?.end_date || "", false),
      ],
    },
  ];

  const taskData = [
    { id: 1, property: "Номер сделки:", value: `#${task?.lead_id || 0}` },
    { id: 2, property: "Мененджер:", value: task?.manager?.fullName },
    { id: 3, property: "Статус:", value: task?.status },
    {
      id: 4,
      property: "Тип задачи:",
      value: toUpperCasefunc(task?.task_type || ""),
      color: "text-[#3583FF]",
    },
    { id: 5, property: "Проект:", value: task?.project?.name },
    { id: 6, property: "Этап сделки:", value: task?.lead?.current_status },
    {
      id: 7,
      property: "Комментарий:",
      value: task?.comment,
    },
  ];

  const handleFinishTask = async () => {
    try {
      if (task?.id) {
        const { data: res, error } = await finishTaskMutation({
          comment: resultComment,
          id: task.id,
        });
        if (res?.meta?.type === ResponseStatusType.SUCCESS) {
          setTaskResultOpen(false);
          showToast("Задача выполнена успешно", "success", 1000);
          setResultComment("");
          refetch();
          setTaskResultOpen(false);
          return;
        }
        if (res?.meta?.type === ResponseStatusType.ERROR && error?.message) {
          showToast(error.message, "success", 1000);
          return;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCopy = () => {
    if (copyAreaRef.current) {
      const textToCopy = copyAreaRef.current.textContent;
      if (textToCopy) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            showToast("Информация скопирована", "success", 1000);
          })
          .catch((err) => {
            showToast("Не удалось скопировать", "error", 1000);
          });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isTaskViewModalOpen}
        className="mx-auto w-full sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px] max-h-[85vh] overflow-y-auto toolbar-scroll "
        onClose={() => setTaskViewModalOpen(false)}
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            #{task?.lead_id}
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setTaskViewModalOpen(false)}
          />
        </div>
        <div>
          <h3 className="text-accent-default text-[16px]">Данные задачи</h3>
          <div className="flex flex-col gap-[10px] text-accent-default text-sm my-5">
            {taskData.map((item) => (
              <div className="flex gap-2" key={item.id}>
                <p className="font-medium">{item.property}</p>
                <p className={`font-normal ${item?.color}`}>{item.value}</p>
              </div>
            ))}
          </div>
          <div className={"flex items-center gap-[13px]"}>
            <h3 className="text-accent-default text-[16px]">Данные клиента</h3>
            <div onClick={handleCopy} className=" cursor-pointer">
              <CopyIcon color={"#8E95A1"} />
            </div>
          </div>
          <div
            className="flex flex-col gap-[10px] text-accent-default text-sm my-5"
            ref={copyAreaRef}
          >
            {clientData.map((item) => (
              <div className="flex flex-wrap gap-2" key={item.id}>
                <p className="font-semibold">{item.property}</p>
                {typeof item.value === "string" ? (
                  <p className={`font-normal`}>{item.value || "..."}</p>
                ) : (
                  <div className="flex items-center gap-2">
                    <div className="py-[5px] flex items-center gap-[10px] rounded px-[6px] bg-bg-default text-[#000000]">
                      <DateIcon color="#3583FF" />{" "}
                      <span>{item.value?.[0]}</span>
                    </div>
                    <div>-</div>
                    <div className="py-[5px] flex items-center gap-[10px] rounded px-[6px] bg-bg-default text-[#000000]">
                      <DateIcon color="#3583FF" />{" "}
                      <span>{item.value?.[1]}</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-[10px]">
          <ButtonForm
            className={
              " px-6 py-3 w-full justify-center  bg-bg-default hover:bg-accent-default text-accent-default hover:text-white-default text-ellipsis whitespace-nowrap"
            }
            text="Завершить"
            onClick={handleCompleteTheTask}
          />
          <ButtonForm
            className={
              " px-6 py-3 w-full justify-center hover:opacity-50  bg-white-default border border-red-default text-red-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Отменить"
            onClick={() => setTaskViewModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        isOpen={taskResultOpen}
        className="mx-auto w-full sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
        onClose={() => {
          setTaskResultOpen(false);
          setResultComment("");
        }}
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Завершение <br /> задачи
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => {
              setTaskResultOpen(false);
              setResultComment("");
            }}
          />
        </div>
        <h3 className="text-sm font-semibold text-accent-default mb-2">
          Результат
        </h3>
        <textarea
          name=""
          rows={4}
          cols={5}
          className="border border-boder-default py-3 px-4 w-full rounded outline-none resize-none"
          id=""
          onChange={(e) => setResultComment(e.target.value)}
        ></textarea>
        <ButtonForm
          disabled={!resultComment && task?.task_type === "первичный показ"}
          className={
            " px-6 py-3 w-full mt-5 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
          }
          text="Отправить"
          onClick={handleFinishTask}
        />
      </Modal>

      <TasksAddModal
        refetch={refetch}
        isOpen={taskAddModalOpen}
        onClose={setTaskAddModalOpen}
      />

      <MortgageModal
        isOpen={mortgageModalOpen}
        setIsOpen={handleApplyForMortgage}
      />

      <Modal
        isOpen={appliedModal}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      >
        <h3 className="text-[25px] font-extrabold text-center mb-5">
          Заявка успешно <br /> отправлена
        </h3>
        <ButtonForm
          text={"Отлично"}
          onClick={() => setAppliedModal(false)}
          className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
        />
      </Modal>
    </>
  );
};

export default TasksModals;
