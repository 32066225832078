import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "data/api";
import { MetaData } from "data/api/exception";
import { ProfileRequestType, User, UserMeta } from "data/api/profile/type";
import { RootState } from "store";
import { handleSelectCity } from "store/HeaderReducer";

export const getUserAction = createAsyncThunk<
  MetaData<User, UserMeta>,
  void,
  { rejectValue: Error }
>("user/getUser", async (_, { rejectWithValue, dispatch }) => {
  try {
    const user = await api.fetch<MetaData<User, UserMeta>>({
      path: "/users/me",
    });
    const savedCity = sessionStorage.getItem("city_id") || user.data.city.id;

    dispatch(handleSelectCity(Number(savedCity)));
    return user;
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error);
  }
});

export const updateUserAction = createAsyncThunk<
  MetaData<User>,
  MetaData<ProfileRequestType>,
  { rejectValue: Error; state: RootState }
>(
  "user/updateUser",
  async ({ data }, { rejectWithValue, dispatch, getState }) => {
    try {
      const oldUser = getState().user.data;
      const user = await api.fetch<MetaData<User>>({
        path: "/users",
        method: "PUT",
        body: { meta: {}, data },
      });

      if (oldUser?.data.city.id !== user.data.city.id) {
        const savedCity =
          sessionStorage.getItem("city_id") || user.data.city.id;

        dispatch(handleSelectCity(Number(savedCity)));
      }
      return user;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue(error);
    }
  },
);

export const logoutUserAction = createAsyncThunk("user/logoutUser", () => {});
